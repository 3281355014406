import * as React from 'react'

import { Grid } from '@mui/material'

import { AppContainer, FundPurchaseForm } from '../components'

export const FundPurchaseView = () => (
  <Grid
    container
    maxWidth='sm'
    mx='auto'
  >
    <AppContainer sx={{ p: 3 }}>
      <FundPurchaseForm />
    </AppContainer>
  </Grid>
)
