import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { FundPurchaseView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FundPurchase = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Comprar cuotas de fondo' />
    <FundPurchaseView />
  </React.Fragment>
)

export default FundPurchase
